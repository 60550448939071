import { navigate } from "astro:transitions/client";
import {
  useState,
  useEffect,
  type ChangeEvent,
  type FocusEvent,
  type FormEvent,
} from "react";
import {
  addContactMetadata,
  addToContactFormContactList,
  createContact,
  getContact, // Import getContact for retrieving existing contacts
  createNewLead,
} from "src/service/mailjetservice";
import Spinner from "../global/Spinner";
import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import flyer from "../../assets/images/flyer.svg";
import { useAuth } from "src/context/AuthContext";
import type { Contact } from "src/types/mailjet/contact";
import type { ContactMetadata } from "src/types/mailjet/contactmetadata";
import whatsapplogo from "../../assets/images/whatsapp.svg";

type Props = {
  lng: string;
};

const ContactForm = ({ lng }: Props) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    product: "",
    amount: "",
    message: "",
  });

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [t, setT] = useState(() => (key: string) => key);
  const { getToken } = useAuth();

  const phone = "+4916098484688"

  useEffect(() => {
    i18next
      .use(HttpBackend)
      .init({
        lng: lng,
        fallbackLng: "de",
        debug: false,
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      })
      .then(() => {
        setT(() => i18next.t.bind(i18next));
      });
  }, [lng]);

  useEffect(() => {
    const validationErrors = validateForm();
    setErrors(validationErrors);
  }, [formData]);

  const toggleShowSpinner = () => {
    setIsShowSpinner((prevState) => !prevState);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    setTouched({ ...touched, [name]: true });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const allTouched = Object.keys(formData).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as Record<string, boolean>);
    setTouched(allTouched);

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      toggleShowSpinner();

      try {
        const contact: Contact = {
          IsExcludedFromCampaigns: false,
          Name: `${formData.firstname} ${formData.lastname}`,
          Email: formData.email,
        };

        let contactId;
        let createContactResponse = await createContact(contact, getToken);
        let data = await createContactResponse.json();

        if (
          createContactResponse.status === 400 &&
          data.ErrorMessage.includes("already exists")
        ) {
          // Existing contact found, retrieve it
          // console.log("contact already exists");
          const existingContactResponse = await getContact(
            formData.email,
            getToken
          );
          const existingContactData = await existingContactResponse.json();
          const existingContact: Contact = existingContactData["Data"][0];
          contactId = existingContact.ID.toString();
        } else {
          const createdContact: Contact = data["Data"][0];
          contactId = createdContact.ID.toString();
        }

        const contactProps: ContactMetadata = {
          firstname: formData.firstname,
          surname: formData.lastname,
          email_address: formData.email,
          mobile_no: formData.phone,
          product_type: formData.product,
          people_amount: formData.amount,
          contact_message: formData.message,
        };

        await addContactMetadata(contactId, contactProps, getToken);
        await createNewLead(contact, contactProps, "contactForm", getToken);

        await addToContactFormContactList(contactId, getToken);

        navigate(`/${lng}/contactsuccess`);
      } catch (error) {
        console.error("Error submitting the contact form", error);
      } finally {
        toggleShowSpinner();
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (): Record<string, boolean> => {
    const validationErrors: Record<string, boolean> = {};

    if (!formData.firstname.trim()) {
      validationErrors.firstname = true;
    }
    if (!formData.lastname.trim()) {
      validationErrors.lastname = true;
    }
    if (!/^[+]?[0-9-() ]{8,}$/.test(formData.phone.trim())) {
      validationErrors.phone = true;
    }
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        formData.email.trim()
      )
    ) {
      validationErrors.email = true;
    }
    if (!formData.product) {
      validationErrors.product = true;
    }
    if (!formData.amount) {
      validationErrors.amount = true;
    }

    return validationErrors;
  };

  return (
    <div
      id="contact"
      className="justify-center flex my-4 selection:bg-slate-500"
    >
      <div className="w-10/12 max-w-screen-md">
        <div className="text-4xl w-full mb-4">{t("contactform.header")}</div>
        <form onSubmit={handleSubmit}>
          <label className="block mb-4 w-full text-xl">
            {t("contact.firstname")}*
            <input
              name="firstname"
              type="text"
              className={`border-2  form-input mt-1 block w-full bg-transparent ${
                errors.firstname && touched.firstname
                  ? "border-red-400"
                  : "dark:border-white border-black"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={formData.firstname}
            />
            {errors.firstname && touched.firstname && (
              <div className="px-2 inline text-red-400 mt-2">
                {t("contact.error.firstname")}
              </div>
            )}
          </label>

          <label className="block mb-4 w-full text-xl">
            {t("contact.lastname")}*
            <input
              name="lastname"
              type="text"
              className={`border-2 form-input mt-1 block w-full bg-transparent ${
                errors.lastname && touched.lastname
                  ? "border-red-400"
                  : "dark:border-white border-black"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={formData.lastname}
            />
            {errors.lastname && touched.lastname && (
              <div className="px-2 inline rounded-md text-red-400 mt-2">
                {t("contact.error.lastname")}
              </div>
            )}
          </label>

          <label className="block mb-4 w-full text-xl">
            {t("contact.email")}*
            <input
              name="email"
              type="text"
              className={`border-2 form-input mt-1 block w-full bg-transparent ${
                errors.email && touched.email
                  ? "border-red-400"
                  : "dark:border-white border-black"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={formData.email}
            />
            {errors.email && touched.email && (
              <div className="px-2 inline text-red-400 mt-2">
                {t("contact.error.email")}
              </div>
            )}
          </label>

          <label className="block mb-4 w-full text-xl">
            {t("contact.phone")}*
            <input
              name="phone"
              type="text"
              className={`border-2 form-input mt-1 block w-full bg-transparent ${
                errors.phone && touched.phone
                  ? "border-red-400"
                  : "dark:border-white border-black"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={formData.phone}
            />
            {errors.phone && touched.phone && (
              <div className="px-2 inline text-red-400 mt-2">
                {t("contact.error.phone")}
              </div>
            )}
          </label>

          {/* Product Selection */}
          <div
            className={`border-2 px-1 mb-2 mt-1 w-full text-xl ${
              errors.product && touched.product
                ? "border-red-400"
                : "border-black"
            }`}
          >
            <div className="block w-full">{t("contact.product.header")}*</div>
            <label className="block">
              <input
                type="radio"
                className="me-2"
                value="private-office"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.po")}
            </label>
            <label className="block w-full">
              <input
                type="radio"
                className="me-2"
                value="flex"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.flex")}
            </label>
            <label className="block w-full">
              <input
                type="radio"
                className="me-2"
                value="fix"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.fix")}
            </label>
            <label className="block w-full">
              <input
                type="radio"
                className="me-2"
                value="business-address"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.address")}
            </label>
            <label className="block w-full">
              <input
                type="radio"
                className="me-2"
                value="meeting-rooms"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.meeting")}
            </label>
            <label className="block w-full">
              <input
                type="radio"
                className="me-2"
                value="meeting-rooms"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.events")}
            </label>
            <label className="block w-full">
              <input
                type="radio"
                className="me-2"
                value="meeting-rooms"
                name="product"
                onChange={handleChange}
              />
              {t("contact.product.choice.training")}
            </label>
          </div>

          {/* Amount of People Selection */}
          <div
            className={`border-2 px-1 mb-2 mt-1 w-full text-xl ${
              errors.amount && touched.amount
                ? "border-red-400"
                : "border-black"
            }`}
          >
            <div className="block w-full">
              {t("contact.amountPeople.header")}*
            </div>
            <label className="block w-full">
              <input
                name="amount"
                type="radio"
                className="me-2"
                value="1"
                onChange={handleChange}
              />
              {t("contact.amountPeople.choice.1")}
            </label>
            <label className="block w-full">
              <input
                name="amount"
                type="radio"
                className="me-2"
                value="2"
                onChange={handleChange}
              />
              {t("contact.amountPeople.choice.2")}
            </label>
            <label className="block w-full">
              <input
                name="amount"
                type="radio"
                className="me-2"
                value="3-4"
                onChange={handleChange}
              />
              {t("contact.amountPeople.choice.3")}
            </label>
            <label className="block w-full">
              <input
                name="amount"
                type="radio"
                className="me-2"
                value="5+"
                onChange={handleChange}
              />
              {t("contact.amountPeople.choice.4")}
            </label>
          </div>

          <label className="text-xl">
            {t("contact.message")}
            <textarea
              name="message"
              className="border-2 border-black dark:border-white  form-input mt-1 block w-full h-20 bg-transparent"
              onChange={handleChange}
              value={formData.message}
            ></textarea>
          </label>

          <div className="inline-flex justify-center w-full mt-2 align-middle">
            <button
              type="submit"
              className="border-2 px-6 py-4 border-black dark:border-white mt-2 text-2xl font-semibold"
            >
              <div className="inline-flex items-center">
                {t("generic.send")}
                {isShowSpinner ? (
                  <span className="ps-1">
                    <Spinner />
                  </span>
                ) : (
                  <img src={flyer.src} className="ms-2 w-6 h-auto" />
                )}
              </div>
            </button>
            <div className="mx-2">{t("contactform.orWhatsApp")}</div>
            <div>
              <a
                href={`https://wa.me/${phone}?text=${encodeURIComponent(
                  `Hallo, ich interessiere mich für ${JSON.stringify(formData)}, habe dazu aber noch Fragen.`
                )}`}
              >
                <img src={whatsapplogo.src} className="w-8 h-auto" />
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
