import { AuthProvider } from "src/context/AuthContext";
import ContactForm from "./ContactForm";

type Props = {
  lng: string;
};

const ContactFormWrapper = ({ lng }: Props) => {
  return (
    <AuthProvider>
      <ContactForm lng={lng} />
    </AuthProvider>
  );
};

export default ContactFormWrapper;
